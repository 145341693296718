import { Fragment, useState } from "react";
import PageTitle from "../../../components/Nav/PageTitle";
import { breadcrumbs } from "..";
import { Link, useNavigate } from "react-router-dom";
import useNotifications from "../../../hooks/useNotifications";
import { renderErrors } from "../../../common/formHelpers";
import { IconPlus, IconSearch } from "@tabler/icons-react";
import ItemSelectorModal from "../../../components/ItemSelectorModal";
import axios from "axios";
import get from "lodash/get";
import CreateSource from "../../Sources/NewSource/CreateSource";
import Modal from "../../../components/Modal";
import CreateDestination from "../../Destinations/NewDestination/CreateDestination";
import ContextHelp from "../../../components/ContextHelp";
import { generateWorkspacePath } from "../../../common/urlHelpers";
import { isInteger, max } from 'lodash';

const NewPipeline = (props) => {
    const navigate = useNavigate();
    const { addNotification } = useNotifications();
    const [showSourceDialog, setShowSourceDialog] = useState(false);
    const [showDestinationDialog, setShowDestinationDialog] = useState(false);

    const [name, setName] = useState('');
    const [active, setActive] = useState(true);
    const [batchProcess, setBatchProcess] = useState(false);
    const [source, setSource] = useState(null);
    const [destination, setDestination] = useState(null);

    const [executing, setExecuting] = useState(false);
    const [error, setError] = useState({ message: null, details: [] });
    const [showSourceSelector, setShowSourceSelector] = useState(false);
    const [showDestinationSelector, setShowDestinationSelector] = useState(false);

    const [retry, setRetry] = useState(false);
    const [retryInterval, setRetryInterval] = useState(0);
    const [maxRetry, setMaxRetry] = useState(0);
    const [retryInfo, setRetryInfo] = useState(false)


    const workspacePath = generateWorkspacePath();
    const sourcePath = `${workspacePath}/sources`
    const destPath = `${workspacePath}/destinations`

    const onRetryChange = (val) => {
        if (val === true) {
            setRetryInterval(1)
            setMaxRetry(1)
            setRetry(true)
        }
        else {
            setRetryInterval(0)
            setMaxRetry(0)
            setRetry(false)
            setRetryInfo(false)
        }
    }

    const onRetryIntervalChange = (val) => {
        if (val < 1) { return }
        setRetryInterval(val)
        if (val >= 60) {
            setRetryInfo(onSecondsReaderHelper(val))
        } else {
            if (retryInfo) {
                setRetryInfo(false)
            }
        }
    }

    const onMaxRetryChange = (val) => {
        const num = parseInt(val)
        if (num < 1 || num > 5) { return }
        setMaxRetry(num)
    }

    const onSecondsReaderHelper = (val) => {
        const days = Math.floor(val / (24 * 60));
        const hours = Math.floor((val % (24 * 60)) / 60);
        const remainingMin = val % 60;
        let result = ''
        if (days > 0) { result += `${days} day${days > 1 ? 's' : ''}` }
        if (hours > 0) { result += `${result ? ' ' : ''}${hours} hour${hours > 1 ? 's' : ''}` }
        if (remainingMin > 0) { result += `${result ? ' ' : ''}${remainingMin} minute${remainingMin > 1 ? 's' : ''}` }

        return result.trim()

    }

    const resetAll = () => {
        setName('');
        setSource(null);
        setDestination(null);
        setActive(true);
        clearErrors();
        setRetryInterval(0)
        setMaxRetry(0)
        setRetry(false)
        setRetryInfo(false)
    }

    const clearErrors = () => {
        setError({ message: null, details: [] });
    }

    const onSourceSelection = (item) => {
        setSource(item);
        setShowSourceSelector(false);
    }

    const onDestinationSelection = (item) => {
        setDestination(item);
        setShowDestinationSelector(false);
    }

    const submit = (e) => {
        e.preventDefault();
        clearErrors();
        setExecuting(true);
        const payload = {
            name,
            sourceId: source.id,
            destinationId: destination.id,
            active,
            batchProcess,
            retrySchedule: {
                afterSeconds: retryInterval * 60, //backend needs it in seconds but the scheduler has a precision of 1 minute
                maxRetries: maxRetry
            }
        };
        let positions = {}
        positions[destination.id] = {
            "x": 850,
            "y": 50
        }
        positions[source.id] = {
            "x": 250,
            "y": 50
        }
        payload.transformationMap =
        {
            "edgeMap": [
                {
                    "source": source.id,
                    "target": destination.id,
                    "containsRoot": true,
                    "label": true
                }
            ],
            "positions": positions
        }
        axios.post(`${workspacePath}/pipelines`, payload).then(response => {
            resetAll();
            addNotification({
                message: 'Pipeline has been created',
                type: 'success'
            });
            navigate('/pipelines');
        }).catch(err => {
            addNotification({
                message: get(err, 'response.data.message', 'An error occurred while creating Pipeline'),
                type: 'error'
            });
            setError(err.response.data);
        }).finally(() => {
            setExecuting(false);
        })
    };

    const onCreateSource = (item) => {
        setSource(item);
        setShowSourceDialog(false)
    }

    const onCreateDestination = (item) => {
        setDestination(item);
        setShowDestinationDialog(false)
    }
    return <Fragment>
        <PageTitle itemKey="pipelines" label="New Pipeline" breadcrumbs={breadcrumbs} />
        <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="mt-5 md:col-span-2 md:mt-0">
                <form onSubmit={submit}>
                    <div className="shadow-inner sm:overflow-hidden sm:rounded-md">
                        <div className="space-y-3 px-4 py-5 sm:p-6">

                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Pipeline Name</span>
                                </label>
                                <input type="text" placeholder="Provide a name for the Pipeline" value={name} className="input input-bordered w-full" onChange={e => setName(e.target.value)} />
                                {renderErrors(error, 'name')}
                            </div>

                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Select a Source</span>
                                </label>
                                <div className="input-group">
                                    <input type="text" readOnly disabled placeholder="Select" value={source?.name || ''} className="input input-bordered" />
                                    <button className="btn btn-square tooltip p-3" data-tip="Select a Source" onClick={e => { e.preventDefault(); setShowSourceSelector(true) }}>
                                        <IconSearch width={24} />
                                    </button>
                                    <button className="btn btn-square tooltip p-2" data-tip="Create Source" onClick={e => { e.preventDefault(); setShowSourceDialog(true) }}>
                                        <IconPlus width={24} />
                                    </button>
                                </div>
                                {renderErrors(error, 'source')}
                            </div>

                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Select a Destination</span>
                                </label>
                                <div className="input-group">
                                    <input type="text" readOnly disabled placeholder="Select" value={destination?.name || ''} className="input input-bordered" />
                                    <button className="btn btn-square tooltip p-3" data-tip="Select a Destination" onClick={e => { e.preventDefault(); setShowDestinationSelector(true) }}>
                                        <IconSearch width={24} />
                                    </button>

                                    <button className="btn btn-square tooltip p-2" data-tip="Create Destination" onClick={e => { e.preventDefault(); setShowDestinationDialog(true) }}>
                                        <IconPlus width={24} />
                                    </button>

                                </div>
                                {renderErrors(error, 'destination')}
                            </div>

                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Active</span>
                                </label>
                                <input type="checkbox" className={`toggle toggle-lg ${active ? 'toggle-success' : ''}`} checked={active} onChange={(e) => setActive(e.target.checked)} />
                                {renderErrors(error, 'active')}
                            </div>

                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Scheduled Batch Processing</span>
                                </label>
                                <input type="checkbox" className={`toggle toggle-lg ${active ? 'toggle-success' : ''}`} checked={batchProcess} onChange={(e) => setBatchProcess(e.target.checked)} />
                                {renderErrors(error, 'batchProcess')}
                            </div>

                            <div className="form-control w-full flex flex-row">
                                <div className="w-1/3 mb-4">

                                    <label className="label">
                                        <span className="label-text">Retry Pipeline</span>
                                    </label>
                                    <input type="checkbox" className={`toggle toggle-lg ${active ? 'toggle-success' : ''}`} checked={retry} onChange={(e) => onRetryChange(e.target.checked)} />

                                </div>
                                <div className={`flex w-2/3 transition-opacity duration-500 ease-in-out ${retry ? 'opacity-100' : 'opacity-0 max-h-0 collapse-content'}`}
                                    style={{ overflow: 'hidden' }}
                                >
                                    <div >

                                        <label className="label">
                                            <span className="label-text">Retry Interval</span>
                                        </label>
                                        <label className="input input-bordered flex items-center gap-2 w-2/3">
                                            <input
                                                type="number"
                                                value={retryInterval}
                                                className="w-[70%]"
                                                placeholder=""
                                                onChange={e => onRetryIntervalChange(e.target.value)}
                                            />
                                            <span className="ml-auto">minute{retryInterval > 1 ? "s" : ""}</span>

                                        </label>
                                        {retryInfo && <div class="label">
                                            <span class="label-text-alt">{retryInfo}</span>
                                        </div>}

                                    </div>
                                    <div >
                                        <label className="label">
                                            <span className="label-text">Maximun Retry</span>
                                        </label>
                                        <input
                                            type="number"
                                            placeholder="Provide a interval for the Pipeline before it reruns"
                                            value={maxRetry}
                                            step={1}
                                            min={0}
                                            className="input input-bordered w-half"
                                            onChange={e => onMaxRetryChange(e.target.value)}
                                        />
                                    </div>
                                    {renderErrors(error, 'retrySchedule')}
                                </div>
                            </div>
                        </div>
                        <div className="bg-base-200 px-4 py-3 text-right sm:px-6">
                            <Link to="/pipelines" className="btn btn-ghost mr-2">Cancel</Link>
                            <button disabled={executing} type="submit" className="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="md:col-span-1">
                <ContextHelp page="create-pipeline" />
            </div>
        </div>
        {showSourceSelector && <ItemSelectorModal url={sourcePath} title="Select a Source" cols={[{ "label": "Name", "datacss": "text-left", "css": "w-1/2", "name": "name" }, { "label": "Active", "datacss": "", "css": "text-center", "name": "active" }, { "label": "Secured", "datacss": "", "css": "text-center", "name": "secured" }]}
            onCancel={() => setShowSourceSelector(false)} onSelect={onSourceSelection} />}
        {showDestinationSelector && <ItemSelectorModal url={destPath} title="Select a Destination" cols={[{ "label": "Name", "datacss": "text-left", "css": "w-1/2", "name": "name" }, { "label": "Active", "datacss": "", "css": "text-center", "name": "active" }]}
            onCancel={() => setShowDestinationSelector(false)} onSelect={onDestinationSelection} />}
        {showSourceDialog && <Modal title="Create Source" onCancel={() => setShowSourceDialog(false)}>
            <CreateSource noShadow={true} onSuccess={onCreateSource} onCancel={() => setShowSourceDialog(false)} /></Modal>}
        {showDestinationDialog && <Modal title="Create Destination" onCancel={() => setShowDestinationDialog(false)}>
            <CreateDestination noShadow={true} onSuccess={onCreateDestination} onCancel={() => setShowDestinationDialog(false)} /></Modal>}

    </Fragment>
}

export default NewPipeline;